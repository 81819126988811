<template>
  <div class="flex flex-col mt-4 gap-5">
    <operating-unit-filter
      @onShow="onShow"
      :hideShowButton="true"
      :multipleTerritory="true"
    />
    <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
      <div class="flex flex-col justify-between gap-2">
        <span>Reason</span>
        <div class="flex justify-between">
          <multiselect
            v-model="reasonMethod"
            :options="reasonOptions"
            :multiple="true"
            :max-height="160"
            :limit="3"
            placeholder="Type Payment Method"
            label="method"
            track-by="id"
          >
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.method }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="
                    props.remove(props.option, $event)
                  "
                />
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-3 sm:w-2/3 w-full whitespace-no-wrap">
      <customer-filter
        :multiple="true"
        :full="true"
        :showClearButton="true"
      />
    </div>
    <div>
      <vs-button @click="handleShowPrompt">Clearing</vs-button>
      <vs-button class="ml-5" @click="onShow">Show</vs-button>
    </div>
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <th width="15%">
          <vs-checkbox v-model="checkedAll">Check All</vs-checkbox>
        </th>
      </template>
      <template slot="tbody">
        <vs-tr v-for="(tr, indextr) in responseData.creditNotes" :key="indextr">
          <vs-td>
            <vs-checkbox
              @click="addChecked(tr.id_kombinasi)"
              :checked="isChecked(tr.id_kombinasi)"
            />
          </vs-td>
          <vs-td>
            <div class="flex flex-row gap-3">
              <vx-tooltip text="View" style="width: fit-content">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  icon="icon-eye"
                  @click="navigateToForm(tr.id, 'view')"
                />
              </vx-tooltip>
              <vx-tooltip
                text="Reverse"
                style="width: fit-content"
                v-if="
                  (tr.cleared_value !== null ||
                    tr.cleared_value !== undefined) &&
                  tr.cleared_value === 0
                "
              >
                <vs-button
                  color="danger"
                  type="line"
                  icon-pack="feather"
                  icon="icon-rewind"
                  @click="handleShowReversePrompt(tr)"
                />
              </vx-tooltip>
            </div>
          </vs-td>
          <vs-td>
            {{ tr.code }}
          </vs-td>
          <vs-td>
            {{ dateFormated(tr.posting_date) }}
          </vs-td>
          <vs-td>
            Code : {{ tr.client_code }} <br />
            Name : {{ tr.client_name }}
          </vs-td>
          <vs-td>
            Code : {{ tr.invoice_code }} <br />
            Paid : {{ priceFormat(tr.paid) }} <br />
            Note : {{ tr.note }} <br />
            Reason : {{ tr.reason }}
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
    <vs-popup
      v-if="renderPrompt"
      fullscreen
      title="Clearing"
      :active.sync="showClearingPopup"
      :button-close-hidden="true"
    >
      <clearing-prompt
        :creditNotes="checkedCreditNote"
        :invoices="checkedInvoices"
        @closePrompt="closePrompt"
      />
    </vs-popup>
    <vs-prompt
      color="danger"
      title="Confirmation"
      @cancel="handleCloseReversePrompt"
      @accept="handleReverse"
      @close="handleCloseReversePrompt"
      :active.sync="showReversePrompt"
    >
      <div class="flex flex-col gap-3">
        <p>
          Are you sure want to reverse
          <span class="font-bold">{{ selectedCreditNote.Code }}?</span>
        </p>
        <div class="flex flex-col gap-1">
          <span class="font-bold">Reversal Date</span>
          <datepicker
            class="w-full"
            v-validate="'required'"
            :inline="false"
            name="Reversal date"
            v-model="reversalDate"
            placeholder="Reversal date"
            :disabled-dates="{
              from: new Date(),
            }"
          />
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import moment from "moment";
import clearingPrompt from "./clearing-prompt.vue";
import Clearing from "../../new_ar_clearing/clearing/clearing.vue";
import operatingUnitFilter from "@/components/operatingUnitFilter/operatingUnitFilter.vue";
import customerFilterVue from "@/components/customerFilter/customerFilter.vue";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    "clearing-prompt": clearingPrompt,
    Clearing,
    datepicker: Datepicker,
    "operating-unit-filter": operatingUnitFilter,
    "customer-filter": customerFilterVue,
  },
  data() {
    return {
      responseData: {},
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "credit_note_number",
        },
        {
          text: "Date",
          value: "credit_note_date",
        },
        {
          text: "Customer",
          value: "supplier",
        },
        {
          text: "Invoice",
          // value: "supplier",
        },
      ],
      params: {
        length: 10,
        page: 1,
        search: "",
        sort: "credit_notes.id",
        order: "desc",
        status: 0,
      },
      checkedCreditNote: [],
      checkedCreditNoteID: [],
      checkedInvoices: [],
      checkedAll: false,
      showClearingPopup: false,
      showReversePrompt: false,
      renderPrompt: false,
      selectedCreditNote: {},
      reversalDate: null,
      territory: {},
      optionTerritory: [],
      reasonOptions: [
        {
          id: 0,
          method: "All",
        },
        {
          id: 1,
          method: "Return",
          query_text: ["Return"],
        },
        {
          id: 2,
          method: "PPH",
          query_text: ["PPH"],
        },
        {
          id: 3,
          method: "Promo",
          query_text: ["Promo"],
        },
      ],
      reasonAllOption: {
        id: 0,
        method: "All",
      },
      reason: [
        {
          id: 0,
          method: "All",
        },
      ],
    };
  },
  mounted() {
    this.getOptionTerritory();
    this.reversalDate = new Date();
  },
  methods: {
    onShow() {
      this.reloadData(this.params);
    },
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      const customer_codes =
        this.$store.getters["customerFilter/getSelectedCustomersCodes"];
      this.$vs.loading();
      params = {
        ...params,
        status: 4, //CN Temporary
        // territory_id: this.territory.id,
        territory_ids,
        customer_codes,
        reasons: this.reasonQuery,
      };
      this.$http
        .get("api/v1/credit-note/temporary/invoice", { params })
        .then((response) => {
          if (response.code < 299) {
            this.responseData = response.data;
          } else {
            this.responseData = {};
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTerritory = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    navigateToForm(id = null, action = "edit") {
      if (!id) {
        this.$router.push({
          name: "cn_temporary.create",
        });
        return;
      }

      if (action === "edit") {
        this.$router.push({
          name: "cn_temporary.edit",
          params: { id: btoa(id) },
        });
      } else if (action === "view") {
        this.$router.push({
          name: "cn_temporary.view",
          params: { id: btoa(id) },
        });
      }
    },
    dateFormated(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    addChecked(id) {
      if (!this.checkedCreditNoteID.includes(id)) {
        this.checkedCreditNoteID.push(id);
        this.checkedCreditNote.push(
          this.responseData.creditNotes.find((cn) => cn.id_kombinasi === id)
        );
      } else {
        const index = this.checkedCreditNoteID.indexOf(id);
        this.checkedCreditNoteID.splice(index, 1);
        this.checkedCreditNote.splice(index, 1);
      }
    },
    isChecked(id) {
      return this.checkedCreditNoteID.includes(id);
    },
    handleConfirm() {
      if (
        this.checkedCreditNoteID.length === 0 ||
        this.checkedCreditNote === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return;
      }

      this.$vs.loading();
    },
    closePrompt() {
      this.showClearingPopup = false;
      this.checkedCreditNote = [];
      this.checkedCreditNoteID = [];
      setTimeout(() => {
        this.renderPrompt = false;
      }, 500);
    },

    validate() {
      if (
        this.checkedCreditNoteID.length === 0 ||
        this.checkedCreditNote === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one credit note",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
          position: "top-right",
        });
        return false;
      }

      // if (
      //   this.checkedCreditNoteID.length > 1 ||
      //   this.checkedCreditNote > 0
      // ) {
      //   this.$vs.notify({
      //     title: "Error",
      //     text: "Cannot clearing multiple credit note",
      //     color: "danger",
      //     iconPack: "feather",
      //     icon: "icon-alert-circle",
      //     position: "top-right",
      //   });
      //   return false;
      // }

      //validate customer
      const cn = this.checkedCreditNote[0];
      for (let i = 1; i < this.checkedCreditNote.length; i++) {
        if (this.checkedCreditNote[i].client_code !== cn.client_code) {
          this.$vs.notify({
            title: "Error",
            text: "Please select credit notes with the same customer",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return false;
        }

        if (this.checkedCreditNote[i].territory_id !== cn.territory_id) {
          this.$vs.notify({
            title: "Error",
            text: "Please select credit notes with the same territory",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
            position: "top-right",
          });
          return false;
        }
      }
      return true;
    },
    handleShowPrompt() {
      if (!this.validate()) {
        return;
      }

      this.renderPrompt = true;
      this.showClearingPopup = true;
    },
    handleShowReversePrompt(tr) {
      this.showReversePrompt = true;
      this.selectedCreditNote = tr;
    },
    handleCloseReversePrompt() {
      this.showReversePrompt = false;
      this.selectedCreditNote = {};
    },
    handleReverse() {
      console.log("selected CN: ",this.selectedCreditNote);
      this.$vs.loading();
      this.$http
        .patch(
          `api/v1/credit-note/temporary/update-status/${this.selectedCreditNote.id}`,
          {
            status: 6,
          }
        )
        .then((response) => {
          if (response.code < 299) {
            this.$vs.notify({
              title: "Success",
              text: "Credit note has been reversed",
              color: "success",
              iconPack: "feather",
              icon: "icon-check-circle",
              position: "top-right",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: response.message,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle",
              position: "top-right",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  watch: {
    checkedAll(value) {
      if (value) {
        console.log("checkedall", this.responseData.creditNotes);
        this.checkedCreditNoteID = this.responseData.creditNotes.map(
          (invoice) => invoice.id_kombinasi
        );
      } else {
        this.checkedCreditNoteID = [];
      }
    },
    "territory": function () {
      this.reloadData(this.params);
    },
  },
  computed: {
    reasonMethod: {
      get() {
        return this.reason;
      },
      set(value) {
        if (value.length === 0) {
          this.reason = [this.reasonAllOption];
          return;
        }
        if (value[0].id === 0) {
          value.shift();
          this.reason = value;
          // this.selectedOperatingUnit = value;
        } else if (value.some((mt) => mt.id === 0)) {
          // this.selectedOperatingUnit = [{ ID: 0, Code: "All", Name: "All" }];
          this.reason = [this.reasonAllOption];
        } else {
          // this.selectedOperatingUnit = value;
          this.reason = value;
        }
      },
    },
    reasonQuery() {
      if (this.reason.length === 1) {
        if (this.reason.some((method) => method.id === 0)) {
          return [];
        } else {
          return this.reason[0].query_text;
        }
      } else {
        const methods = [];
        this.reason.forEach((method) => {
          methods.push(...method.query_text);
        });
        return methods;
      }
    },
  }
};
</script>
